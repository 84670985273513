import React from 'react';
import ModalRegister from '../../Shared/Modal';

const Enroll = ({ future }) => {
  return (
    <div className="px-5 py-10 bg-gray-100 text-center relative">
      <div className="text-center md:px-50 text-primary text-4xl font-bold mb-10">
        Garanta já a sua vaga!
      </div>
      <div className="text-center md:px-50 text-gray-600 text-3xl font-semibold leading-6 mb-10">
        {!future
          ? <span>Encontrou sua turma? Não perca mais tempo e matricule-se já! Venha ser a próxima aprovação da família Pró!</span>
          : <span>Quer se cadastrar para saber em primeira mão quando nossa matricula para o curso Intensivo estiver aberta?  </span>
        }
      </div>

      {future
        ?
        (<div className="">
          <a href="https://api.whatsapp.com/send?phone=5548988111499&text=Ol%C3%A1,%20tenho%20interesse%20na%20pr%C3%B3xima%20turma%20de%20intensivo%20e%20queria%20ser%20avisado(a)%20quando%20abrirem%20matr%C3%ADculas!" target='_blank' rel="noopener noreferrer"
            className='bottom-3 mx-auto mt-10 bg-accent text-primary-400 py-2 px-15 rounded-2xl text-xl font-bold overflow-hidden disabled:border disabled:border-accent disabled:bg-white disabled:opacity-50 disabled:cursor-default'>
            Me avise!
          </a>
        </div>)
        :
        <ModalRegister label={'Garantir agora!'}></ModalRegister>
      }
    </div>
  )
}

export default Enroll
