import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React from 'react';

import about from '../../../images/svg/about.svg';

const Students = ({ students }) => {

  const studentImages = useStaticQuery(
    graphql`
        query {
          images: allFile(filter: {dir: {regex: "/images/students/"}}) {
            edges {
              node {
                name,
                base,
                childImageSharp {
                  gatsbyImageData(
                    width: 200
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
            }
          }
        }
      `
  )

  const images = studentImages.images.edges.map(({ node }) => node);

  const getStudentImage = (student) => {
    const image = images.find(image => image.base === student.photo);
    return image;
  }

  return (
    <div className="px-5 py-10 relative w-full overflow-hidden">
      <div className="text-center md:px-50 text-primary text-4xl font-bold mb-10">
        Quem fez, foi aprovado
      </div>
      <div className="text-center md:px-50 text-gray-600 text-3xl font-semibold leading-6 mb-10">
        Temos aprovados da Família Pró nas mais mais diversas carreiras.
      </div>

      <div className="flex flex-wrap md:flex-nowrap justify-center">
        {students.map((student, index) => (
          <div className="shadow-md bg-white rounded-2xl md:mx-10 mx-3 my-5 pb-5 pt-2 px-3 text-center z-99" key={index}>
            <GatsbyImage imgClassName="pr-3 pt-3" image={getImage(getStudentImage(student))} alt={student?.name} />
            <div className="mt-5 text-3xl text-primary font-bold">{student.name}</div>
            <div className="text-xl text-gray-500 font-bold">{student?.course}</div>
            <div className="text-xl text-gray-500 font-bold">{student?.college}</div>
          </div>
        ))}
      </div>

      <img className="absolute overflow-hidden top-5 md:max-h-full max-w-full right-3 z-10" src={about} alt="Sobre"/>

    </div>
  )
}

export default Students
