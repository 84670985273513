import React from 'react'
import product1 from '../../../images/svg/courses/product1.svg'
import product2 from '../../../images/svg/courses/product2.svg'
import product3 from '../../../images/svg/courses/product3.svg'
import product4 from '../../../images/svg/courses/product4.svg'
import product5 from '../../../images/svg/courses/product5.svg'

const Products = ({ course }) => {
  return (
    <div className="py-15 md:px-10 px-5 text-center relative bg-gray-100">
      <div className="text-primary text-4xl font-bold mb-5">Sua aprovação é nossa meta</div>

      <div className="flex flex-wrap flex-col md:flex-row justify-center md:px-10 z-99">

        <div className="flex-1 bg-white shadow-md hvr-grow rounded-xl m-4 z-99">
          <div className="p-8">
            <div>
              <img className="mx-auto" src={product1} alt="Oficina de Redação"/>
              <div className="text-3xl text-primary font-bold text-center leading-6 my-3">Oficina de redação</div>
              <div className="text-lg font-semibold text-center text-gray-500 leading-4">
                Aqui, você tem sua redação corrigida e comentada, podendo marcar atendimento individualizado para tirar suas dúvidas e melhorá-las.
              </div>
            </div>
          </div>
        </div>

        {course?.toLowerCase() !== 'semiextensivo' &&
          <div className="flex-1 bg-white shadow-md hvr-grow rounded-xl m-4 z-99">
            <div className="p-8">
              <div>
                <img className="mx-auto" src={product2} alt="Material direcionado"/>
                <div className="text-3xl text-primary font-bold text-center leading-6 my-3">Material direcionado</div>
                <div className="text-lg font-semibold text-center text-gray-500 leading-4">
                  Nosso material próprio é direcionado aula a aula, com até 20 exercícios, dividido em níveis de dificuldade para facilitar seu aprendizado.
                </div>
              </div>
            </div>
          </div>
        }

        {course?.toLowerCase() === 'semiextensivo' &&
          <div className="flex-1 bg-white shadow-md hvr-grow rounded-xl m-4 z-99">
            <div className="p-8">
              <div>
                <img className="mx-auto" src={product5} alt="Coordenação e orientação"/>
                <div className="text-3xl text-primary font-bold text-center leading-6 my-3">Coordenação e orientação</div>
                <div className="text-lg font-semibold text-center text-gray-500 leading-4">
                  Aqui prezamos pela proximidade, você pode conversar com seu coordenador sempre que precisar. É uma familía de verdade,  juntos  nessa caminhada.
                </div>
              </div>
            </div>
          </div>
        }

        <div className="flex-1 bg-white shadow-md hvr-grow rounded-xl m-4 z-99">
          <div className="p-8">
            <div>
              <img className="mx-auto" src={product3} alt="Atendimento Pedagógico e Psicológico"/>
              <div className="text-3xl text-primary font-bold text-center leading-6 my-3">Atendimento Pedagógico e Psicológico</div>
              <div className="text-lg font-semibold text-center text-gray-500 leading-4">
                Durante todo o curso você terá disponivel uma psicóloga para te ajudar e orientar, sempre que achar necessário.
              </div>
            </div>
          </div>
        </div>

        <div className="flex-1 bg-white shadow-md hvr-grow rounded-xl m-4 z-99">
          <div className="p-8">
            <div>
              <img className="mx-auto" src={product4} alt="Plantão de dúvidas"/>
              <div className="text-3xl text-primary font-bold text-center leading-6 my-3">Plantão de dúvidas</div>
              <div className="text-lg font-semibold text-center text-gray-500 leading-4">
                Ficou com alguma dúvida da matéria ou quando foi fazer os exercícios elas apareceram? Não tem problema, aqui não ficam dúvidas!
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Products
