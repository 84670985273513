import React from 'react'
import about from '../../../images/svg/about.svg'
import ModalRegister from '../../Shared/Modal'

const Values = ({ whatsapp, course }) => {
  return (
    <div className="w-full max-w-full overflow-hidden py-20 md:px-50 px-5 text-center relative">
      <div className="text-primary text-4xl font-bold mb-5">Valores</div>
      <div className="text-gray-600 text-3xl font-semibold mb-4 mt-7 leading-7">
        Temos a melhor estrutura e o curso mais completo com um preço justo para você conquistar seu futuro!
      </div>

      {whatsapp
        ?
        (<div className="pt-7">
          <a href="https://api.whatsapp.com/send?phone=5548988111499&text=Ol%C3%A1,%20tenho%20interesse%20na%20pr%C3%B3xima%20turma%20de%20intensivo%20e%20queria%20ser%20avisado(a)%20quando%20abrirem%20matr%C3%ADculas!" target='_blank' rel="noopener noreferrer"
            className='bottom-3 mx-auto mt-10 bg-accent text-primary-400 py-2 px-15 rounded-2xl text-xl font-bold overflow-hidden disabled:border disabled:border-accent disabled:bg-white disabled:opacity-50 disabled:cursor-default'>
            Me avise quando houver uma nova turma!
          </a>
        </div>)
        :
        <ModalRegister label={'Consultar valor do curso!'} course={course}></ModalRegister>
      }



      <img className="absolute overflow-hidden top-5 md:max-h-full max-w-full left-3 -z-1" src={about} alt="sobre" />
    </div>
  )
}

export default Values;